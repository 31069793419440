import React, {PureComponent} from 'react';
import Section from "../components/Content/Section";

class ErrorPage extends PureComponent {
	render() {
		return (
			<div className="content">
				<div className="center_block">
					<Section id="error_page">
						<p>404 Ошибка</p>
						<p>Такой страницы увы нет...</p>
					</Section>
				</div>
			</div>
		);
	}
}

export default ErrorPage;
