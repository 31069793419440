import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import icon from './close.svg';
import './ButtonClose.scss';

class ButtonClose extends PureComponent {
	render() {
		return (
			<button className="close_button" onClick={this.props.onCallBack}>
				<img src={icon} alt="Закрыть"/>
			</button>
		);
	}
}

ButtonClose.propTypes = {
	onCallBack: PropTypes.func.isRequired,
};

export default ButtonClose;
