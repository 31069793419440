import React, {PureComponent} from 'react';

class Form extends PureComponent {

	onHandleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		}, () => this.props.onChange(this.state));
	};

	render() {
		return (
			<form onChange={this.onHandleChange}>
				{this.props.children}
			</form>
		);
	}
}

export default Form;
