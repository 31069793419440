import React, {PureComponent} from 'react';
import {inject, observer} from "mobx-react";
import ServicesDropItem from "./ServicesDropItem";
import './ServicesDrop.scss';

@inject('Store')
@observer
class ServicesDrop extends PureComponent {

	state = {
		index: 0,
	};

	onHandleClick = (e) => {
		this.setState({index: e})
	};

	render() {
		const {Store} = this.props;
		return (
			<div className="service_drop_block">
				{
					Store.drop_service.map((item, index) => {
						return (
							<ServicesDropItem
								key={index}
								id={index}
								index={this.state.index}
								active={this.state.index}
								service_title={item.title_service}
								service_content={item.text_service}
								onCallBack={this.onHandleClick}
							/>
						)
					})
				}
			</div>
		);
	}
}

export default ServicesDrop;
