import React, {PureComponent} from 'react';
import ButtonClose from "../Buttons/ButtonClose";

class Attention extends PureComponent {
	render() {
		return (
			<div className={this.props.visible ? 'attention_block attention_block_active' : 'attention_block'}>
				<ButtonClose onCallBack={this.props.onCallBack}/>
				<div className="attention_title">Уважаемые клиенты!</div>
				<p>
					В качестве мер по борьбе с распространением <span>коронавируса</span> - мы ежедневно проверяем наших выездных мастеров и
					курьеров, а также выдаем им защитные маски.
				</p>
			</div>
		);
	}
}

export default Attention;
