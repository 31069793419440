import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import back from '../Content/pic/back.svg';
import {Link} from "react-router-dom";
import './Title.scss';

class Title extends PureComponent {
	render() {

		const style = {
			textAlign: this.props.align,
		};

		return (
			<div
				style={style}
				className={this.props.align === "center" ? 'page_title' : 'page_title page_title_flex'}
			>
				{this.props.home ? <Link to={this.props.link}><img src={back} alt="Назад"/></Link> : null}
				{this.props.h1 ? <h1>{this.props.title}</h1> : this.props.title}
			</div>
		);
	}
}

Title.propTypes = {
	title: PropTypes.string,
	align: PropTypes.string.isRequired,
	home: PropTypes.bool,
	link: PropTypes.string,
};

export default Title;
