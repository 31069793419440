import React, {Fragment, PureComponent} from 'react';
import Modal from "./index";
import params from "../../params";
import RequestModal from "./RequestModal";
import callIcon from "../App/pic/request-call-icon.svg";
import statusIcon from "../App/pic/request-status-icon.svg";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class ModalBlock extends PureComponent {
	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				{
					Store.request_call ? (
						<Modal
							active={Store.request_call}
							title={params.request_call_title}
						>
							<RequestModal
								text={params.request_call_text}
								icon={callIcon}
								alt="Обратный звонок"
								button="Закзаать звонок"
								type="call"
							/>
						</Modal>
					) : null
				}
				{
					Store.request_status ? (
						<Modal
							active={Store.request_status}
							title={params.request_status_title}
						>
							<RequestModal
								text={params.request_status_text}
								icon={statusIcon}
								alt="Проверить статус"
								button="Проверить"
								type="status"
							/>
						</Modal>
					) : null
				}
			</Fragment>
		);
	}
}

export default ModalBlock;
