import React, {PureComponent, Fragment} from 'react';
import MainPageHead from "../components/MainPageHead";
import Section from "../components/Content/Section";
import Title from "../components/Title/Title";
import SplitContent from "../components/Content/SplitContent";
import ServicesDrop from "../components/Content/ServicesDrop";
import {Helmet} from "react-helmet";
import Review from "../components/Review";

class MainPage extends PureComponent {
	render() {
		return (
			<Fragment>
				<Helmet>
					<meta property="og:site_name" content={`Repair Store Mi - Качественный ремонт в Москве!`} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={`Repair Store Mi - Качественный ремонт в Москве!`} />
					<meta property="og:image" content="https://repair.store-mi.ru/api/image.jpg" />
					<meta property="og:description" content={`Repair Store Mi - Ремонт в Москве!`} />
					<meta property="og:url" content={`https://repair.store-mi.ru/`} />
					<meta charSet="utf-8" />
					<title>{`Repair Store Mi - Качественный ремонт!`}</title>
					<link rel="canonical" href="https://repair.store-mi.ru/" />
				</Helmet>
				<MainPageHead/>
				<div className="center_block">
					<Section id="repair_services">
						<Title align="center" title="Наши услуги по ремонту"/>
						<SplitContent>
							<ServicesDrop/>
						</SplitContent>
					</Section>
					<Section id="reviews">
						<Title align="center" title="Нам доверяют"/>
						<Review slider={true}>
						</Review>
					</Section>
				</div>
			</Fragment>
		);
	}
}

export default MainPage;
