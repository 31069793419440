import React, {PureComponent} from 'react';
import './Loading.scss';

class Loading extends PureComponent {
	render() {
		return (
			this.props.center ? (
				<div className="loading-spinner-ripple-center">
					<div className="loading_spinner">
						<div/>
						<div/>
					</div>
					<p>Загрузка сайта...</p>
				</div>
				) : (
				<div className="loading-spinner-ripple">
					<div className="loading_spinner">
						<div/>
						<div/>
					</div>
				</div>
			)
		);
	}
}

export default Loading;
