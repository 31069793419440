import React, {PureComponent} from 'react';
import './AdvBanner.scss';
import icon1 from './pic/adv-icon1.svg';
import icon2 from './pic/adv-icon2.svg';
import icon3 from './pic/adv-icon3.svg';
import AdvIconItem from "./AdvIconItem";
import params from "../../../params";
import Order from "../../../Form/Order";

class AdvBanner extends PureComponent {
	render() {
		return (
			<div className="adv_banner">
				<div className="adv_banner_title">Удобство и надежность</div>
				<div className="center_block">
					<div className="adv_icons">
						<AdvIconItem pic={icon1} title="Используем только оригинальные запчасти"/>
						<AdvIconItem pic={icon2} title="Выезжаем по всей Москве и области"/>
						<AdvIconItem pic={icon3} title="Бесплатный выезд мастера или доставка в сервис"/>
					</div>
					
					<div className="need_questions">
						<div className="nq_left">
							<div className="nq_title">Остались вопросы?</div>
							<div className="nq_phone">
								Звоните: <a href={`tel: ${params.phone}`}>{params.phone}</a>
							</div>
						</div>
						<div className="nq_right">
							<ul>
								<li>ремонт на дому</li>
								<li>Ремонтируем за 15 минут</li>
								<li>Со скидкой 25%</li>
							</ul>
						</div>
					</div>

					<div className="adv_order">
						<Order button="Заказать ремонт" paddingTop={40}/>
					</div>
					
				</div>
			</div>
		);
	}
}

export default AdvBanner;
