import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Input.scss';
import InputMask from "react-input-mask";

class Input extends PureComponent {

	state = {
		value: ''
	};

	componentDidMount() {
		if (this.props.value) {
			this.setState({value: this.props.value});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.value !== prevProps.value) {
			this.setState({value: this.props.value});
		}
	}

	onHandleChange = (e) => {
		this.setState({value: e.target.value});
	};

	onHandleClick = (e) => {
		e.target.select();
	};

	render() {

		const style = {
			borderRadius: this.props.radius ? `${this.props.radius}px 0 0 ${this.props.radius}px` : null,
			maxWidth: this.props.width,
		};

		return (
			this.props.type === "phone" ? (
				<InputMask
					style={style}
					className="form_input"
					mask="8 (999) 999-99-99"
					name={this.props.name}
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.onHandleChange}
				/>
			) : (
				<input
					style={style}
					className="form_input"
					type={this.props.type}
					name={this.props.name}
					placeholder={this.props.placeholder}
					hidden={this.props.hidden}
					onChange={this.onHandleChange}
					onClick={this.onHandleClick}
					value={this.state.value}
				/>
			)
		);
	}
}

Input.defaultProps = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	radius: PropTypes.number,
	placeholder: PropTypes.string.isRequired,
};

export default Input;
