import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import ButtonClose from "../Buttons/ButtonClose";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class Modal extends PureComponent {

	onHandleClick = () => {
		const {Store} = this.props;
		Store.request_call = false;
		Store.request_status = false;
	};

	render() {
		return (
			<Fragment>
				<div className="modal">
					<ButtonClose onCallBack={this.onHandleClick}/>
					<div className="modal_title">{this.props.title}</div>
					{this.props.children}
				</div>
				<div className="modal_overlay" onClick={this.onHandleClick}/>
			</Fragment>
		);
	}
}

Modal.propTypes = {
	active: PropTypes.bool.isRequired,
};

export default Modal;
