import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Section from "../components/Content/Section";
import CatalogItem from "../components/CatalogItem";
import Loading from "../components/Loading";
import CatalogFilter from "../components/CatalogFilter";
import {Helmet} from "react-helmet";
import params from "../params";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

@inject('Store')
@observer
class Posts extends PureComponent {
	constructor(props) {
		super(props);
		const {Store} = this.props;
		Store.posts = [];
		Store.catalog_filter = 1;
	}

	state = {
		per_page: 100,
	};

	componentDidMount() {
		const {Store} = this.props;
		this.MakeRequest().then(r => {
			if (r.status) Store.posts = r.data;
		});
	}

	MakeRequest = async () => {
		const {Store} = this.props;
		return await Store.GET(this.state, this.props.slug);
	};

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<ScrollToTopOnMount />
				<Helmet>
					<meta charSet="utf-8"/>
					<title>{`Repair Store Mi - ${this.props.title}`}</title>
					<link rel="canonical" href={`/${this.props.slug}/${this.props.slug}`}/>
				</Helmet>
				<div className="content">
					<div className="center_block">
						<Section id="page_content">
							<CatalogFilter filter={this.props.slug !== 'electrical-goods'} title={this.props.title}/>
							<div className="catalog_block">
								<div className="catalog_info_content">
									<p><b>Мы занимаемся ремонтом большинства марок телефонов и электротехники.</b> Ниже приведены лишь некоторые из них.</p>
									<p>Если Вашего устройства нет ниже в списке, ничего страшного. <b>Просто <a href={`tel:${params.phone}`}>позвоните нам</a> и Мы вас проконсульитрем по стоимости ремонта.</b></p>
								</div>
								<div className="catalog_grid">
									{
										Store.posts.length ? (
											Store.posts.map(item => {
												return (
													parseInt(item.acf.system) === Store.catalog_filter || this.props.slug === 'electrical-goods' ? (
														<CatalogItem
															key={item.id}
															id={item.id}
															link={item.slug}
															category_slug={this.props.slug}
															title={item.title.rendered}
															content={item.content.rendered}
															tags={item.tags}
															pic={item.better_featured_image.source_url}
														/>
													) : null
												)
											})
										) : <Loading/>
									}
								</div>
							</div>
						</Section>
					</div>
				</div>
			</Fragment>

		);
	}
}

export default Posts;
