import React, {PureComponent} from 'react';
import './MainPageHead.scss';
import logo from './pic/logo-big.svg';
import params from "../../params";
import icon1 from './pic/icon1.svg';
import icon2 from './pic/icon2.svg';
import icon3 from './pic/icon3.svg';
import Order from "../../Form/Order";
import down from './pic/down.svg';
import logo_white from './pic/logo-big-white.svg';
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class MainPageHead extends PureComponent {
	render() {
		const {Store} = this.props;
		return (
			<div className="main_page_head">
				<div className="center_block">
					<div className="main_page_content">
						<div className="main_page_logo"><img src={Store.inner_width > 1024 ? logo : logo_white} alt=""/></div>
						<div className="main_page_icons_phone">
							<ul>
								<li><img src={icon1} alt=""/></li>
								<li><img src={icon2} alt=""/></li>
								<li><img src={icon3} alt=""/></li>
							</ul>
							<div className="main_page_phone"><a href={`tel: ${params.phone}`}>{params.phone}</a></div>
						</div>
						<div className="main_page_header"><h1>{params.header_title}</h1></div>
						<div className="main_page_subheader">{params.header_subtitle}</div>
						<Order paddingTop={30} button="Заказать ремонт"/>
					</div>
				</div>
				<div className="down_icon"><img src={down} alt="Просмотр сайта"/></div>
			</div>
		);
	}
}

export default MainPageHead;
