import React, {PureComponent, Fragment} from 'react';
import Order from "../../Form/Order";
import Status from "../../Form/Status";

class RequestModal extends PureComponent {

	render() {
		return (
			<Fragment>
				<div className="modal_text_position">
					<div className="modal_icon">
						<img src={this.props.icon} alt={this.props.alt}/>
					</div>
					<div className="modal_text">
						{this.props.text}
					</div>
				</div>
				<div className="modal_page_position">
					{
						this.props.type === "call" ? (
							<Order
								paddingTop={5}
								button={this.props.button}
							/>
						) : (
							<Status
								paddingTop={5}
								button={this.props.button}
							/>
						)
					}
				</div>
			</Fragment>
		);
	}
}

export default RequestModal;
