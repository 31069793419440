import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import '../Button.scss';

class ButtonOrange extends PureComponent {
	render() {

		const style = {
			borderRadius: this.props.radius ? `0 ${this.props.radius}px ${this.props.radius}px 0` : null,
		};

		return (
			<button
				className="page_button"
				onClick={this.props.onCallBack}
				style={style}
			>
				{this.props.title}
			</button>
		);
	}
}

ButtonOrange.propTypes = {
	title: PropTypes.string.isRequired,
	onCallBack: PropTypes.func.isRequired,
};

export default ButtonOrange;
