import React, {PureComponent} from 'react';
import no_photo from './no-photo.jpg';

class ReviewItem extends PureComponent {
	render() {
		return (
			<div className="review_item">
				<div className="rev_pic"><img src={this.props.photo ? this.props.photo.url : no_photo} alt={this.props.name}/></div>
				<div className="rev_name">{this.props.name}</div>
				<div className="rev_review">{this.props.review}</div>
			</div>
		);
	}
}

export default ReviewItem;
