import React, {PureComponent} from 'react';
import Form from "../../Form";
import Title from "../Title/Title";
import ButtonOrange from "../Buttons/ButtonOrange";
import ImageDropZone from "../ImageDropZone";
import {inject, observer} from "mobx-react";
import cogoToast from "cogo-toast";

@inject('Store')
@observer
class ReviewForm extends PureComponent {

	state = {
		my_name: '',
		my_content: '',
		my_photo: null,
		error: false,
	}

	onHandleChange = (e) => {
		console.log(e);
		this.setState({...this.state, ...e})
	};

	onHandleClick = async (e) => {
		const {Store} = this.props;
		e.preventDefault();
		const obj = {
			my_email: 'donotpreply@repair.store-mi.ru',
			my_photo: this.state.my_photo,
			my_name: this.state.my_name,
			my_content: this.state.my_content,
		}
		const request = await Store.sendReview(obj);
		if (request.data.status !== "validation_failed") {
			this.setState({
				my_name: '',
				my_content: '',
				my_photo: null,
			}, () => {
				cogoToast.success(request.data.message, {position: 'top-right', heading: 'Успех!'});
			})
		} else {
			cogoToast.error(request.data.message, {position: 'top-right', heading: 'Ошибка!'});
		}
	};

	imagePicked = (e) => {
		this.setState({...this.state, my_photo: e.file}, () => console.log(this.state))
	}

	render() {
		return (
			<div className="rev_form">
				<Title align="left" title="Вы можете оставить отзыв"/>
				<Form onChange={this.onHandleChange}>
					<div className="form_position">
						<div className="form_left">
							<ImageDropZone
								width={230}
								height={200}
								imagePicked={this.imagePicked}
								showButton={true}
								showDeleteButton={true}

							/>
						</div>
						<div className="form_fight">
							<input type="text" placeholder="ФИО" value={this.state.my_name} name="my_name"/>
							<textarea placeholder="Отзыв" value={this.state.my_content} name="my_content" id="rew_content"/>
							{
								this.state.error ? <p>Error</p> : null
							}
							<ButtonOrange title="Отправить" onCallBack={this.onHandleClick}/>
						</div>
					</div>
				</Form>
			</div>
		);
	}
}

export default ReviewForm;
