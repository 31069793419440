import React, {PureComponent} from 'react';
import './Footer.scss';
import footer_logo from './footer-logo.svg';
import params from "../../params";
import AdvBanner from "./AdvBanner";

class Footer extends PureComponent {
	render() {
		return (
			<div className="footer_block">
				<AdvBanner/>
				<footer>
					<div className="center_block">
						<div className="footer_position">
							<div className="footer_logo"><img src={footer_logo} alt={params.header_title}/></div>
							<div className="footer_address">{params.address}</div>
							<div className="footer_copyright">{params.footer_copyright}</div>
						</div>
					</div>
				</footer>
			</div>

		);
	}
}

export default Footer;
