import React, {PureComponent} from 'react';
import {NavLink} from "react-router-dom";
import rev from './pic/rev.svg';
import delivery from './pic/delivery.svg';
import contacts from './pic/contacts.svg';

class MobileRightNav extends PureComponent {
	render() {
		return (
			<ul>
				<li><NavLink to="/delivery"><img src={delivery} alt="Доставка"/></NavLink></li>
				<li><NavLink to="/reviews"><img src={rev} alt="Отзывы"/></NavLink></li>
				<li><NavLink to="/contacts"><img src={contacts} alt="Контакты"/></NavLink></li>
			</ul>
		);
	}
}

export default MobileRightNav;
