import React, {PureComponent} from 'react';
import './Content.scss';
import ButtonIcon from "../Buttons/ButtonIcon";
import checkStatus from './pic/check-status.svg';
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

@inject('Store')
@observer
class SplitContent extends PureComponent {

	checkStatus = () => {
		const {Store} = this.props;
		Store.request_status = true;
	};

	onHandleClick = () => {
		const {Store} = this.props;
		Store.catalog_filter = 7;
	};

	render() {

		return (
			<div className="page_split_content">
				<div className="page_split_content_left">{this.props.children}</div>
				<div className="page_split_content_right">
					<div className="content_sticky">
						<ButtonIcon
							icon={checkStatus}
							alt="Проверить статус заказа"
							onCallBack={this.checkStatus}
							title="Проверить статус заказа"
						/>
						<Link
							to="/notebook"
							onClick={this.onHandleClick}
						><span className="r_button r_laptop">Ремонт ноутбуков</span></Link>
						<Link
							to="/tablets"
							onClick={this.onHandleClick}
						><span className="r_button r_tablet">Ремонт Планшетов</span></Link>
						<Link
							to="/phones"
							onClick={this.onHandleClick}
						><span className="r_button r_phone">Ремонт Телефонов</span></Link>
						<Link
							to="/electrical-goods"
							onClick={this.onHandleClick}
						><span className="r_button r_electro">Электросамокаты</span></Link>
						<Link
							to="/trade-in"
							onClick={this.onHandleClick}
						><span className="r_button r_trade_in">Trade-In</span></Link>
					</div>
				</div>
			</div>
		);
	}
}

export default SplitContent;
