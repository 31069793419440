import React, {PureComponent} from 'react';
import Interweave from 'interweave';

class ServicesDropItem extends PureComponent {
	render() {
		return (
			<div className={this.props.index === this.props.id ? 'service_drop_item active' : 'service_drop_item'}>
				<button onClick={() => this.props.onCallBack(this.props.id)}>{this.props.service_title}</button>
				<div className="service_drop_content">
					<Interweave content={this.props.service_content} />
				</div>
			</div>
		);
	}
}

export default ServicesDropItem;
