import React, {PureComponent, Fragment} from 'react';
import './App.scss';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {inject, observer} from "mobx-react";
import route from "../../params/route";
import ModalBlock from "../Modal/ModalBlock";
import Header from "../Header";
import Footer from "../Footer";
import MainPage from "../../page/MainPage";
import ErrorPage from "../../page/ErrorPage";
import './media.scss';
import axios from "axios";
import SinglePost from "../../page/SinglePost";

@inject('Store')
@observer
class App extends PureComponent {

	handleWindowResize = () => {
		const {Store} = this.props;
		Store.inner_width = window.innerWidth;
	};

	componentDidMount() {
		const {Store} = this.props;
		Store.getService();
		Store.getReviews();
		Store.inner_width = window.innerWidth;
		window.addEventListener('resize', this.handleWindowResize);
	}
	
	render() {
		axios.defaults.baseURL = 'https://repair.store-mi.ru/api/wp-json/wp/v2';
		axios.defaults.headers.common['Content-Type'] = 'application/json, multipart/form-data';

		return (
			<Fragment>
				<ModalBlock/>
				<Router>
					<Header/>
					<Switch>
						<Route exact path="/" component={MainPage}/>
						{
							route.map(item => {
								return (
									<Route key={item.slug} exact path={`/${item.slug}`}>
										{item.template}
									</Route>
								)
							})
						}
						{
							route.map(item => {
								return (
									<Route key={item.slug} exact path={`/${item.slug}/:id`}>
										<SinglePost slug={item.slug}/>
									</Route>
								)
							})
						}
						<Route path="*" component={ErrorPage}/>
					</Switch>
				</Router>
				<Footer/>
			</Fragment>
		);
	}
}

export default App;
