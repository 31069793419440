import React, {PureComponent} from 'react';
import {Link} from "react-router-dom";
import './CatalogItem.scss';
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class CatalogItem extends PureComponent {

	render() {
		return (
			<div className="catalog_item">
				<div className="catalog_pic">
					<Link to={`/${this.props.category_slug}/${this.props.link}`}>
						<span className="catalog_item_pic">
							<img src={this.props.pic} alt={this.props.title}/>
						</span>
					</Link>
				</div>
				<div className="catalog_title">
					<Link to={`/${this.props.category_slug}/${this.props.link}`}>
						{this.props.title}
					</Link>
				</div>
			</div>
		);
	}
}

export default CatalogItem;
