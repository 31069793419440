const params = {
	title: 'Store Mi',
	phone: '8 (499) 112-36-07',
	header_title: 'Качественный Ремонт',
	header_subtitle: 'ноутбуков, телефонов, планшетов, Смарт часов в  Москве',
	request_call_title: 'Заказать ремонт со скидкой 25%',
	request_call_text: 'Введите свой номер, так мы сможем с вами связаться и уточнить детали заказа.',
	request_status_title: 'Проверить статус заказа',
	request_status_text: 'Вы можете проверить статус заказа заполнив форму.',
	form_error: 'Ошибка валидации формы',
	form_success: 'Вы успешно отправили сообщение!',
	request_status_success: 'Мы нашли вашу заявку!',
	request_status_error: 'Увы, такой заявки нет! Попробуйте проверить данные.',
	address: 'г. Москва, Пятницкое шоссе 18 (436 павильон)',
	footer_copyright: 'Сервисный центр «Store Mi» ©2013-2020',
	filter: [
		{
			id: 1,
			label: 'Apple',
		},
		{
			id: 2,
			label: 'Android',
		}
	],
	sub_filter: [
		{
			id: 1,
			label: 'Все модели',
		},
		{
			id: 2,
			label: 'Xiaomi'
		},
		{
			id: 3,
			label: 'Samsung'
		}
	],
};

export default params;
