import React from "react";
import Page from "../page/Page";
import Posts from "../page/Posts";

const route = [
	{
		slug: 'phones',
		nav: true,
		nav_label: 'Телефоны',
		template: <Posts slug='phones' title='Телефоны'/>,
	},
	{
		slug: 'tablets',
		nav: true,
		nav_label: 'Планшеты',
		template: <Posts slug='tablets' title='Планшеты'/>,
	},
	{
		slug: 'smart-watch',
		nav: true,
		nav_label: 'Смарт часы',
		template: <Posts slug='smart-watch' title='Смарт часы'/>,
	},
	{
		slug: 'electrical-goods',
		nav: false,
		nav_label: 'Электросамокаты',
		template: <Posts slug='electrical-goods' title='Электросамокаты'/>,
	},
	{
		slug: 'notebook',
		nav: true,
		nav_label: 'Ноутбуки',
		template: <Page slug='notebook' title='Ремонт ноутбуков'/>,
	},
	{
		slug: 'contacts',
		template: <Page slug='contacts' title='Контакты'/>,
	},
	{
		slug: 'delivery',
		template: <Page slug='delivery' title='Доставка'/>,
	},
	{
		slug: 'reviews',
		template: <Page slug='reviews' title='Отзывы'/>,
	},
	{
		slug: 'trade-in',
		template: <Page slug='trade-in' title='Трейд-ин'/>,
	}
];

export default route;
