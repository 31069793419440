import React, {PureComponent} from 'react';
import './CatalogFilter.scss';
import Title from "../Title/Title";
import {inject, observer} from "mobx-react";
import params from "../../params";

@inject('Store')
@observer
class CatalogFilter extends PureComponent {

	onHandleClick = (id) => {
		const {Store} = this.props;
		Store.catalog_filter = id;
	}

	render() {
		const {Store} = this.props;
		return (
			<div className="catalog_filter_block">
				<Title home={true} link="/" align="left" title={this.props.title}/>
				{
					this.props.filter ? (
						<div className="filter_buttons">
							{
								params.filter.map(item => {
									return (
										<button
											key={item.id}
											onClick={() => this.onHandleClick(item.id)}
											className={Store.catalog_filter !== item.id ? 'filter_button' : 'filter_button active'}
										>
											{item.label}
										</button>
									)
								})
							}
						</div>
					) : null
				}

			</div>
		);
	}
}

export default CatalogFilter;
