import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from "mobx-react";
import '../Form.scss';
import Form from "../index";
import Input from "../Input";
import ButtonOrange from "../../components/Buttons/ButtonOrange";
import params from "../../params";

@inject('Store')
@observer
class Status extends PureComponent {

	state = {
		data: {
			status_id: '',
			status_content: '',
		},
		status: false,
		error: false,
	};

	componentDidMount() {
		const {Store} = this.props;
		Store.getRequests();
	}

	onHandleChange = (e) => {
		this.setState({status: false, error: false}, () => {
			this.setState({data: {...this.state.data, ...e}, status: false, error: false})
		});
	};

	onHandleSubmit = (e) => {
		e.preventDefault();
		this.setState({error: false, status: false});
		const {Store} = this.props;
		const status = Store.status.find(item => item.status_id === this.state.data.status_id);
		if (status !== undefined) {
			this.setState({data: status, status: true, error: false});
		} else {
			this.setState({error: true, status: false})
		}
	};

	render() {

		const style = {
			paddingTop: this.props.paddingTop,
		};

		return (
			<div className="main_page_order" style={style}>
				<Form onChange={this.onHandleChange}>
					{
						this.state.status ? (
							<div className="status_agree">
								<div className="status_title">{params.request_status_success}</div>
								<div className="status_content_block">
									<div className="status_num">Номер заявки: <b>{this.state.data.status_id}</b></div>
									<div className="status_content">Статус заказа: {this.state.data.status_request}</div>
								</div>
							</div>
						) : null
					}
					{
						this.state.error ? (
							<div className="status_none">
								<div className="status_title">{params.request_status_error}</div>
								<div className="status_content_block_error">
									<div className="status_num"><b>{this.state.data.status_id}</b>: Данная заявка не найдена</div>
								</div>
							</div>
						) : null
					}
					<div className="main_page_order_position">
						<Input
							type="number"
							value={this.state.data.status_id}
							placeholder="Номер заявки"
							width={300}
							radius={22}
							name="status_id"
						/>
						<ButtonOrange
							radius={22}
							title={this.props.button}
							onCallBack={this.onHandleSubmit}
						/>
					</div>
					<div className="form_agree">
						Нажимая кнопку <span>«{this.props.button}»</span>, вы подтверждаете свое согласие на обработку персональных данных
					</div>
				</Form>
			</div>
		);
	}
}

Status.propTypes = {
	button: PropTypes.string.isRequired,
	paddingTop: PropTypes.number.isRequired,
};

export default Status;
