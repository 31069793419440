import {action, observable} from "mobx";
import axios from 'axios';
import qs from 'qs';

class Store {

	@observable request_call = false; // Модальное окно заказать звонок
	@observable request_status = false; // Модальное окно проверить статус заявки
	@observable inner_width = ''; // Ширина браузера
	@observable posts = []; // массив постов из рубрики
	@observable post = {}; // Пост
	@observable page = {}; // Страница
	@observable catalog_filter = 1; // Фильтр Apple или Android

	@action GET = async (options, slug) => {
		const url = `${axios.defaults.baseURL}/${slug}`;
		return axios.get(url, {params: options})
	};

	/** Получаем запрос на статус ремонта **/
	@observable status = {};
	@action getRequests = () => {
		const url = `https://repair.store-mi.ru/api/wp-json/acf/v3/pages/46/requests/`;
		axios.get(url)
			.then(response => {
				this.status = response.data.requests;
			})
	};
	/** ---- **/

	/** Получаем Услуги на главной **/
	@observable drop_service = [];
	@action getService = () => {
		const url = `${axios.defaults.baseURL}/pages/60`;
		axios.get(url)
			.then(response => {
				this.drop_service = response.data.acf.services;
			})
	}
	/** ---- **/

	@action sendCall = (my_email, my_phone) => {
		const url = 'https://repair.store-mi.ru/api/wp-json/contact-form-7/v1/contact-forms/87/feedback';
		const options = {my_email, my_phone};
		axios.post(url, qs.stringify(options))
	};

	@action sendCallModel = (my_email, my_phone, my_model) => {
		const url = 'https://repair.store-mi.ru/api/wp-json/contact-form-7/v1/contact-forms/409/feedback';
		const options = {my_email, my_phone, my_model};
		axios.post(url, qs.stringify(options))
	};

	@action sendRepair = (my_email, my_phone) => {
		const url = 'https://repair.store-mi.ru/api/wp-json/contact-form-7/v1/contact-forms/88/feedback';
		const options = {my_email, my_phone};
		axios.post(url, qs.stringify(options))
	};

	@observable reviews = {
		data: [],
		status: false,
	};
	@action getReviews = async () => {
		const url = `${axios.defaults.baseURL}/pages/?slug=reviews`;
		const data = await axios.get(url);
		this.reviews.data = data.data[0].acf.reviews;
		this.reviews.status = data.status === 200;
	};
	@action sendReview = async (obj) => {
		const url = 'https://repair.store-mi.ru/api/wp-json/contact-form-7/v1/contact-forms/343/feedback';
		const formData = new FormData();
		formData.append('my_name', obj.my_name);
		formData.append('my_email', obj.my_email);
		formData.append('my_content', obj.my_content);
		formData.append('my_photo', obj.my_photo);
		return await axios.post(url, formData);
	};

}

const store = new Store();
export default store;
