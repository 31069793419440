import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Form from "../index";
import Input from "../Input";
import ButtonOrange from "../../components/Buttons/ButtonOrange";
import '../Form.scss';
import error from '../pic/error.svg';
import success from '../pic/success.svg';
import params from "../../params";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class Order extends PureComponent {

	state = {
		data: {
			email: 'donotreply@repair.store-mi.ru',
			phone: '',
			model: '',
		},
		status: false,
		error: false,
	};

	componentDidMount() {
		if (this.props.post) {
			this.setState({data: {...this.state.data, model: this.props.title}})
		}
	}

	onHandleChange = (obj) => {
		this.setState({status: false, error: false}, () => {
			for (let key in obj) {
				if (obj.hasOwnProperty(key)){
					if (key === "phone") {
						const phone = obj[key].replace(/[^+\d]/g, '');
						this.setState({data: {...this.state.data, phone}});
					} else {
						this.setState({data: {...this.state.data, ...obj}})
					}
				}
			}
		});
	};

	onHandleSubmit = (e) => {
		e.preventDefault();
		const {Store} = this.props;
		if (this.state.data.phone.length !== 11) {
			this.setState({
				error: true,
				status: false,
			});
		} else {
			if (this.props.post) {
				Store.sendCallModel(this.state.data.email, this.state.data.phone, this.state.data.model);
			} else {
				console.log('Send Phone')
				Store.sendCall(this.state.data.email, this.state.data.phone)
			}
			this.setState({
				error: false,
				status: true,
				data: {...this.state.data, phone: ''}
			}, () => {
				setTimeout(() => {
					Store.request_call = false;
				}, 2500)
			});
		}
		setTimeout(() => {
			this.setState({error: false, status: false})
		}, 2000)
	};

	render() {

		const style = {
			paddingTop: this.props.paddingTop,
		};

		return (
			<div className="main_page_order" style={style}>
				{
					this.props.title ? <p>Вы можете заказать ремонт <b>{this.props.title}</b> заполнив форму</p> : null
				}
				<Form onChange={this.onHandleChange}>
					<div className="main_page_order_position">
						<Input
							type="phone"
							value={this.state.data.phone}
							placeholder="Телефон"
							width={300}
							radius={22}
							name="phone"
						/>
						<Input
							placeholder="Email"
							radius={22}
							name="email"
							hidden
							type="email"
							value={this.state.data.email}
						/>
						<ButtonOrange
							radius={22}
							title={this.props.button}
							onCallBack={this.onHandleSubmit}
						/>
					</div>
					{
						this.state.status ? (
							<div className="form_message form_success"><img src={success} alt={params.form_success}/>{params.form_success}</div>
						) : null
					}
					{
						this.state.error ? (
							<div className="form_message form_error"><img src={error} alt={params.form_error}/>{params.form_error}</div>
						) : null
					}
					<div className="form_agree">
						Нажимая кнопку <span>«{this.props.button}»</span>, вы подтверждаете свое согласие на обработку персональных данных
					</div>
				</Form>
			</div>
		);
	}
}

Order.propTypes = {
	button: PropTypes.string.isRequired,
	paddingTop: PropTypes.number.isRequired,
};

export default Order;
