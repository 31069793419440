import React, {PureComponent, Fragment} from 'react';
import menu from './pic/menu.svg';
import route from "../../params/route";
import {NavLink} from "react-router-dom";
import ButtonOrange from "../Buttons/ButtonOrange";
import {inject, observer} from "mobx-react";

@inject('Store')
@observer
class MobileNav extends PureComponent {

	state = {
		visible: false,
	};

	onHandleClick = () => {
		this.setState({visible: !this.state.visible})
	};

	onClickOutside = () => {
		this.props.onCallBack();
	}

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<div className="mobile_nav">
					<button onClick={this.onHandleClick}>
						<img src={menu} alt="Мобильное меню"/>
					</button>
				</div>
				<div onClick={this.onClickOutside} className={this.state.visible ? 'mobile_nav_block active' : 'mobile_nav_block'}>
					<ul className="nav_links">
						{
							route.map(item => {
								return (
									item.nav ? (
										<li key={item.slug} onClick={() => this.setState({visible: false})}>
											<NavLink to={`/${item.slug}`}>{item.nav_label}</NavLink>
										</li>
									) : null
								)
							})
						}
						<li><NavLink onClick={() => this.setState({visible: false})} to="/electrical-goods">Электросамокаты</NavLink></li>
						<li><NavLink onClick={() => this.setState({visible: false})} to="/trade-in">Trade-In</NavLink></li>
						<li><NavLink onClick={() => this.setState({visible: false})} to="/delivery">Доставка</NavLink></li>
						<li><NavLink onClick={() => this.setState({visible: false})} to="/reviews">Отзывы</NavLink></li>
						<li><NavLink onClick={() => this.setState({visible: false})} to="/contacts">Контакты</NavLink></li>
						<li  onClick={() => this.setState({visible: false})}><ButtonOrange title="Заказать звонок" onCallBack={() => Store.request_call = true}/></li>
					</ul>
				</div>
			</Fragment>
		);
	}
}

export default MobileNav;
