import React, {Fragment, PureComponent} from 'react';
import Title from "../components/Title/Title";
import Section from "../components/Content/Section";
import {inject, observer} from "mobx-react";
import Interweave from "interweave";
import Loading from "../components/Loading";
import '../style/Page.scss';
import {Helmet} from "react-helmet";
import Order from "../Form/Order";
import Review from "../components/Review";
import ReviewForm from "../components/Review/ReviewForm";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

@inject('Store')
@observer
class Page extends PureComponent {
	constructor(props) {
		super(props);
		const {Store} = this.props;
		Store.page = {};
	}

	componentDidMount() {
		const {Store} = this.props;
		this.makeRequest().then(r => {
			Store.page = {...r.data[0]};
		});
	}

	makeRequest = async () => {
		const {Store} = this.props;
		return Store.GET({slug: this.props.slug}, 'pages');
	};

	render() {
		const {Store} = this.props;
		return (
			<Fragment>
				<ScrollToTopOnMount />
				<Helmet>
					<meta property="og:site_name" content={`Repair Store Mi - ${this.props.title}`} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={`Repair Store Mi - ${this.props.title}`} />
					<meta property="og:description" content={`Repair Store Mi - Ремонт ${this.props.title} в Москве!`} />
					<meta property="og:url" content={`https://repair.store-mi.ru/${this.props.slug}`} />
					<meta charSet="utf-8" />
					<title>{`Repair Store Mi - ${this.props.title}`}</title>
					<link rel="canonical" href={`/${this.props.slug}`} />
				</Helmet>
				<div className="content">
					<div className="center_block">
						<Section id="page_content">
							{
								Store.page.status ? (
									<Fragment>
										<Title home={true} link="/" align="left" title={Store.page.title.rendered}/>
										<div className="static_page">
											<div className="product_content">
												<div className="content_box">

													{
														this.props.slug === 'contacts' ? (
															<iframe
																src="https://yandex.ru/map-widget/v1/?um=constructor%3A4558090fe6a47aa4d367903dc3af1ca498dce423d5a4f2c3edbcff702201b8f2&amp;source=constructor"
																width="100%" height="500" title="yandex_map_contacts" frameBorder="0"/>
														): null
													}
													<Interweave content={Store.page.content.rendered} />

												</div>
												{
													this.props.slug === 'notebook' ? (
														<div className="page_order">
															<Order title="ноутбука" button="Заказать ремонт" paddingTop={40}/>
														</div>
													) : null
												}
												{
													this.props.slug === 'reviews' ? (
														<Fragment>
															<ReviewForm/>
															<Review slider={false}/>
														</Fragment>

													) : null
												}
											</div>
										</div>
									</Fragment>
								) : <Loading/>
							}
						</Section>
					</div>
				</div>
			</Fragment>
			
		);
	}
}

export default Page;
