import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "mobx-react";
import Store from './store/Store';
import App from "./components/App";

const Root = (
  <Provider Store={Store}>
    <App />
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));
