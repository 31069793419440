import React, {PureComponent} from 'react';
import './Header.scss';
import {inject, observer} from "mobx-react";
import {Link, NavLink} from "react-router-dom";
import logo from './pic/logo.svg';
import attention from './pic/attention.svg';
import ButtonOrange from "../Buttons/ButtonOrange";
import ButtonIcon from "../Buttons/ButtonIcon";
import Attention from "./Attention";
import {clickOutside} from "@epranka/react-click-outside";
import route from "../../params/route";
import MobileRightNav from "./MobileRightNav";
import MainNavMob from "./MainNavMob";
import MobileNav from "./MobileNav";

@inject('Store')
@observer
class Header extends PureComponent {

	state = {
		attention: false
	};

	onHandleAttention = () => {
		this.setState({attention: !this.state.attention});
	};

	onClickOutside(e) {
		this.setState({attention: false})
	}

	render() {
		const {Store} = this.props;
		return (
			<header>
				<div className="center_block">
					<div className="header_position">
						<div className="header_left">
							<div className="logo"><Link to="/"><img src={logo} alt="Store Mi"/></Link></div>
							{
								Store.inner_width > 768 ? (
									<ul className="nav_links">
										{
											Store.inner_width > 960 ? (
												route.map(item => {
													return (
														item.nav ? (
															<li key={item.slug}>
																<NavLink to={`/${item.slug}`}>{item.nav_label}</NavLink>
															</li>
														) : null
													)
												})
											) : <MainNavMob/>
										}
									</ul>
								) : null
							}
						</div>
						<div className="header_right">
							{
								Store.inner_width > 768 ? (
									<div className="header_right_links">
										{
											Store.inner_width > 1080 ? (
												<ul>
													<li><NavLink to="/delivery">Доставка</NavLink></li>
													<li><NavLink to="/reviews">Отзывы</NavLink></li>
													<li><NavLink to="/contacts">Контакты</NavLink></li>
												</ul>
											) : <MobileRightNav/>
										}
									</div>
								) : null
							}
							<ButtonIcon alt="Внимание" icon={attention} onCallBack={this.onHandleAttention}/>
							{
								Store.inner_width > 768 ? (
									<ButtonOrange title="Заказать звонок" onCallBack={() => Store.request_call = true}/>
								) : null
							}
							<Attention onCallBack={this.onHandleAttention} visible={this.state.attention}/>
							{
								Store.inner_width <= 768 ? (
									<MobileNav onCallBack={() => this.setState({attention: false})}/>
								) : null
							}
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default clickOutside(Header);
