import React, {PureComponent, Fragment} from 'react';
import Section from "../components/Content/Section";
import SplitContent from "../components/Content/SplitContent";
import Title from "../components/Title/Title";
import {inject, observer} from "mobx-react";
import '../style/Page.scss'
import Interweave from "interweave";
import Order from "../Form/Order";
import Loading from "../components/Loading";
import {Helmet} from "react-helmet";

@inject('Store')
@observer
class SinglePost extends PureComponent {
	constructor(props) {
		super(props);
		const {Store} = this.props;
		Store.post = {};
	}

	componentDidMount() {
		const {Store} = this.props;
		const link = document.location.pathname.split('/');
		this.makeRequest(link[2]).then(r => {
			Store.post = {...r.data[0]};
		});
	}

	makeRequest = async (link) => {
		const {Store} = this.props;
		return Store.GET({slug: link}, this.props.slug);
	};

	render() {
		
		const {Store} = this.props;
		
		return (
			<div className="content">
				<div className="center_block">
					<Section id="page_catalog">
						{
							Store.post.status ? (
								<Fragment>
									<Helmet>
										<meta charSet="utf-8" />
										<meta property="og:site_name" content={`Repair Store Mi - ${Store.post.title.rendered}`} />
										<meta property="og:type" content="website" />
										<meta property="og:title" content={`Repair Store Mi - ${Store.post.title.rendered}`} />
										<meta property="og:image" content="https://repair.store-mi.ru/api/image.jpg" />
										<meta property="og:description" content={`Repair Store Mi - Ремонт ${Store.post.title.rendered} в Москве!`} />
										<meta property="og:url" content={`https://repair.store-mi.ru/${this.props.slug}/${this.props.slug}`} />
										<title>{`Repair Store Mi - ${Store.post.title.rendered}`}</title>
										<link rel="canonical" href={`/${this.props.slug}`} />
									</Helmet>
									<Title
										home={true}
										link={`/${this.props.slug}`}
										h1={true}
										align="left"
										title={Store.post.title.rendered}
									/>
									<SplitContent>
										<div className="product_page">
											<div className="product_page_pic_logo">
												<div className="product_pic">
													<img
														src={Store.post.better_featured_image.source_url}
														alt={Store.post.title.rendered}
													/>
												</div>

											</div>
											<div className="product_content">
												<h2>СТОИМОСТЬ УСЛУГ ПО РЕМОНТУ</h2>
												<div className="content_box">
													<Interweave content={Store.post.content.rendered}/>
												</div>
												<div className="page_order">
													<Order
														post={true}
														title={Store.post.title.rendered}
														button="Заказать ремонт"
														paddingTop={40}
													/>
												</div>
											</div>
										</div>
									</SplitContent>
								</Fragment>
							) : <Loading/>
						}
					</Section>
				</div>
			</div>
		);
	}
}

export default SinglePost;
