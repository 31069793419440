import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './Content.scss';

class Section extends PureComponent {
	render() {
		return (
			<section className="page_section" id={this.props.id}>
				{this.props.children}
			</section>
		);
	}
}

Section.propTypes = {
	id: PropTypes.string.isRequired,
};

export default Section;
