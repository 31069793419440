import React, {PureComponent} from 'react';

class AdvIconItem extends PureComponent {
	render() {
		return (
			<div className="adv_icons_item">
				<img src={this.props.pic} alt={this.props.title}/>
				<p>{this.props.title}</p>
			</div>
		);
	}
}

export default AdvIconItem;