import React, {PureComponent} from 'react';
import {inject, observer} from "mobx-react";
import Loading from "../Loading";
import ReviewItem from "./ReviewItem";
import './Review.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

@inject('Store')
@observer
class Review extends PureComponent {
	render() {
		const {Store} = this.props;
		const settings = {
			dots: true,
			infinite: true,
			dotsClass: 'my_dots',
			arrows: false,
			autoplay: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
			],
			autoplaySpeed: 4000,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 3
		};
		return (
			<div className={this.props.slider ? 'review_list_slider' : 'review_list_grid'}>
				{
					this.props.slider ? (
						Store.reviews.status ? (
							<Slider {...settings}>
								{
									Store.reviews.data.map((item, index) => {
										return <ReviewItem key={index} photo={item.photo} name={item.name} review={item.review}/>
									})
								}
							</Slider>
						) : <Loading/>
					) : (
						Store.reviews.status ? (
							Store.reviews.data.map((item, index) => {
								return <ReviewItem key={index} photo={item.photo} name={item.name} review={item.review}/>
							})
						) : <Loading/>
					)
				}
			</div>
		);
	}
}

export default Review;
