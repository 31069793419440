import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class ButtonIcon extends PureComponent {
	render() {
		return (
			<button className={this.props.title ? 'button_icon_title' : 'button_icon'} onClick={this.props.onCallBack}>
				<img src={this.props.icon} alt={this.props.alt}/>
				{
					this.props.title ? this.props.title : null
				}
			</button>
		);
	}
}

ButtonIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	onCallBack: PropTypes.func.isRequired,
	title: PropTypes.string,
};

export default ButtonIcon;
